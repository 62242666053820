import React from 'react'
import ReactDOM from 'react-dom'
import loadable from '@loadable/component'
import HeaderApp from '../applications/components/HeaderApp'
import RouterApp from '../applications/components/RouterApp/index'
import StoreRoot from '../applications/components/StoreApp/components/Root'
import '../applications/utils/sentry-init'
import {
  componentExistsOnPage,
  getComponentProps,
  getRootElement,
} from '../applications/utils/ssr'
import CustomStoreHeaderApp from '../applications/components/CustomStoreHeaderApp/'

const rootElem = getRootElement('ServerRouterApp')
const serverRouterProps = getComponentProps('ServerRouterApp')

if (componentExistsOnPage('HeaderApp')) {
  const props = getComponentProps('HeaderApp')

  ReactDOM.hydrate(<HeaderApp {...props} />, getRootElement('HeaderApp'))
}

if (componentExistsOnPage('CustomStoreHeaderApp')) {
  const props = getComponentProps('CustomStoreHeaderApp')

  ReactDOM.hydrate(
    <CustomStoreHeaderApp {...props} />,
    getRootElement('CustomStoreHeaderApp')
  )
}

const ItemRoot = loadable(() =>
  import(
    /* webpackPrefetch: true */ '../applications/components/ItemApp/components/Root'
  )
)

const style = rootElem.querySelector('style')

// нужно для styled components
if (style) {
  rootElem.before(style)
}

ReactDOM.hydrate(
  <RouterApp
    components={{ ItemRoot, StoreRoot }}
    state={serverRouterProps.state}
    materialUI={serverRouterProps.materialUI}
  />,
  rootElem
)
